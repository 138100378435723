import React from 'react';
import cx from "classnames";
const ReviewCard = ({ data, className }) => {
    let classNames = cx('review-card', className);
    return <div className={classNames}>
        <div className='review-card-content m-2'>
            <div className='color-white review-content text-left fs-c'>{data.review}</div>
            <div className='review-card-user text-start'>
                {data.profile &&
                    <a href={data.profile} className='review-card-link fs-c color-gray4' target='_blank' rel="noreferrer">
                        <div className="d-flex mtb-10" style={{ gap: '20px' }}>
                            <img src={data.user} alt='user avatar' width={48} height={48} style={{borderRadius: '50%'}}/>
                            <div className="d-flex flex-column mtb-10">
                                <p className='color-white'>{data.name}</p>
                                <p className='color-gray fs-c'>{data.role}</p>
                            </div>
                        </div>
                    </a>
                }
                {!data.profile &&
                    <div className="d-flex mtb-10" style={{ gap: '20px' }}>
                        <img src={data.user} alt='user avatar' width={48} height={48} style={{borderRadius: '50%'}}/>
                        <div className="d-flex flex-column">
                            <p className='color-white'>{data.name}</p>
                            <p className='color-gray fs-c'>{data.role}</p>
                        </div>
                    </div>
                }

            </div>
        </div>
    </div>
}

export default ReviewCard;