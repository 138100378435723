import React from 'react';
import cx from "classnames";
import arrowUp from '../../assets/images/arrow-up.svg'
const CommonBtn = ({ onClick, title, className }) => {
  let classNames = cx( 'd-flex justify-content-around', className);
  return (
    <div className={classNames} onClick={onClick} style={{gap:'20px'}}>
      <p style={{margin:'auto'}}>{ title }</p>
      {className.includes('active-btn') && <img src={arrowUp} alt='arrow-up' width={20} height={20} style={{margin:'auto'}} className='arrow-up'/>}
    </div>
  );
};

export default CommonBtn;