import React, { useState } from 'react';
import { faqData } from 'src/config/faqData';
import FaqCard from 'src/components/faqCard';
import curveImg from "../../assets/images/curve.svg"
import CommonBtn from 'src/components/button/CommonBtn';
import SuccessModal from 'src/components/successModal';
const FAQ = () => {
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setSuccess(false);
    };
    
    return <div className='faq-section' id='faq'>
        <div className='container'>
            <div className="d-flex flex-lg-row flex-md-row flex-sm-column justify-content-between">
                <span className='text-left'>
                    <p className='fs-h2 color-white'>Frequently</p>
                    <p className='fs-h2 color-white'>Asked</p>
                    <p className='fs-h2 color-primary'>Question</p>
                </span>
                <div className="faq-container">
                    {faqData.map((item, index) => (
                        <FaqCard data={item} index={open} seq={index} key={index} setOpen={setOpen} />
                    ))}
                </div>
            </div>
            <div className="subscribe-container mt-5">
                <div class="invest-container">
                    <img src={curveImg} alt="" srcset="" />
                    <div class="circle1"></div>
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center mtb-200">
                    <div className="d-flex">
                        <p className="fs-h2 color-white">Subscribe</p> &nbsp;&nbsp;
                        <p className="fs-h2 color-primary">Newsletter</p>
                    </div>
                    <div className="color-white m-2 text-center">
                        Subscribe to our newsletter to receive all the latest news & updates.
                    </div>
                    <div className="d-flex flex-lg-row flex-md-row flex-sm-column mtb-50" style={{ gap: '10px' }}>
                        <input type="text" placeholder="Enter your E-mail" className="contact-form-input fs-b" />
                        <div className='d-flex justify-content-center'>
                            <CommonBtn className="active-btn color-white" title="Subscribe" onClick={() => { document.body.classList.add('modal-open'); setSuccess(true) }}/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <SuccessModal isOpen={success} onClose={closeModal}/>
    </div>
}

export default FAQ;