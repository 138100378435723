import React from 'react';
import closeModalImg from "../assets/images/x.svg";
import checkImg from "../assets/images/circle-check.png";

const SuccessModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    <img src={closeModalImg} alt="Close" />
                </button>
                <div className="wrapper text-center">
                    <div className='checker'>
                        <img src={checkImg} alt="envelope"  width={32} height={32}/>
                    </div>
                    <h3 className='fs-s0 color-white'>Success!</h3>
                    <span className='color-white' style={{ opacity: 0.8 }}>Your application has been successfully received. Our managers will contact you as soon as possible. </span>
                </div>
            </div>
        </div>
    );
};

export default SuccessModal;