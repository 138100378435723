import React from 'react';
import cx from "classnames";
import plusImg from "../assets/images/plus.png";
import minusImg from "../assets/images/minus.png";
import { addLineBreak } from 'src/libs/validate';

const FaqCard = ({ data, className, seq, setOpen, index }) => {
    let classNames = cx('faq-card', className);
    return <div className={classNames}>
        <div className='faq-card-content m-2'>
            <div className="mtb-50 question-content" onClick={() => { setOpen(seq) }}>
                <div className="d-flex">
                    {index === seq && <img src={minusImg} className='m-2' alt="minus" srcset="" width={24} height={24} />}
                    {index !== seq && <img src={plusImg} alt="plus" className='m-2' srcset="" width={24} height={24} />}
                    <p className='fs-s0 color-white m-2'>{data.question}</p>
                </div>
                <div className={`color-white answer-content` + (index === seq ? " open" : '')} style={{opacity:0.8}}>
                    {index === seq && addLineBreak(data.answer)}
                </div>
            </div>
        </div>
    </div>
}

export default FaqCard;