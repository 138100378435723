import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import logo from '../../assets/images/logo.svg';
import CommonBtn from 'src/components/button/CommonBtn';
import FormModal from 'src/components/formModal';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setOpen(false);
    };
    return (<>
        <Navbar variant="dark" expand="lg" className={`header-container navbar-a`}>
            <Navbar.Brand as={Link} onClick={()=> {navigate('/')}} className='d-flex'>
                <img
                    style={{ width: '103px' }}
                    src={logo}
                    className="d-inline-block align-top header-logo"
                    alt="Armstep Logo"
                />
            </Navbar.Brand>
            <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
                <Nav className="mr-auto d-flex">
                    <Link to="home" smooth={true} duration={500} className='color-white fs-c'> About </Link>
                    <Link to="invest" smooth={true} duration={500} className='color-white fs-c'> How we invest </Link>
                    <Link to="team" smooth={true} duration={500} className='color-white fs-c'> Portfolio </Link>
                    <Link to="faq" smooth={true} duration={500} className='color-white fs-c'> FAQ </Link>
                </Nav>
            </Navbar.Collapse>
            <Nav className='mobile-toggle-wrapper'>
                <CommonBtn className='common-btn get-started color-white' title="Get Started" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
            </Nav>
        </Navbar>
        <FormModal isOpen={open} onClose={closeModal} />
    </>
    );
};

export default Header;