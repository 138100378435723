import Home from "./views/HomePage/index";
import Privacy from "./views/privacy";

const routes = [
  {
    title: 'Home',
    href: '/home',
    component: Home,
    children: [],
    index: true
  },
  {
    title: 'Privacy',
    href: '/privacy',
    component: Privacy,
    children: [],
    index: true
  },
  
]

export default routes;