import React from 'react';

const TeamMemberCard = ({ data }) => {
    return (
        <div className="d-flex flex-column" style={{ gap: '20px' }}>
            <div className="team-member-image">
                <img src={data.imgUrl} alt="member-avatar" />
            </div>
            <span>
                <h3 className="color-white fs-s0 text-left">{data.name}</h3>
                <p className="color-primary fs-c text-left">{data.role}</p>

            </span>
            <p className='member-detail text-left fs-c color-white'>
                {data.about}
            </p>
        </div>
    );
};

export default TeamMemberCard;