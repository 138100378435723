import React, { useState } from 'react';
import closeModalImg from "../assets/images/x.svg";
import CommonBtn from './button/CommonBtn';
import SuccessModal from './successModal';


const FormModal = ({ isOpen, onClose, }) => {
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setOpen(false);
    };
    
    if (!isOpen) return null;
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    <img src={closeModalImg} alt="Close" />
                </button>

                <div className="member-about-section d-flex flex-column justify-content-center align-items-center" style={{ gap: '15px' }}>
                    <p className="color-white fs-s1">Let's build a strong union</p>
                    <p className="color-white text-center" style={{ opacity: 0.8 }}>Fill out the form and our managers will contact you to build further joint work:</p>
                    <input type="text" placeholder="Enter your name" className="contact-form-input fs-b" />
                    <input type="text" placeholder="Enter your E-mail" className="contact-form-input fs-b" />
                    <CommonBtn className="active-btn color-white" title="Get Started" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }}/>
                </div>
            </div>
            <SuccessModal isOpen={open} onClose={closeModal}/>
        </div>
    );
};

export default FormModal;