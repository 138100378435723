import React from 'react';
import vector from '../assets/logos/vector.svg';
const Privacy = () => {

    return <div className='privacy-section' id='faq'>
        <div className='container' style={{ textAlign: 'center' }}>
            <div className='crypto text-center' style={{ display: 'inline-flex' }}>
                <img src={vector} alt="vector" srcset="" width={20} height={20} />
                <p className='fs-c color-white' style={{ margin: 'auto' }}>Last Updated: 09 Dec, 2024</p>
            </div>
            <p className='color-white' style={{ fontSize: 70, fontWeight: 600 }}>Privacy Policy</p>
            <p className='color-white my-4' style={{ maxWidth: 700, margin: 'auto' }}>Welcome to DeFina! <br />
                At DeFina, we prioritize your privacy and are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you access our services.</p>
            <div className="policy-container text-start my-3" style={{ maxWidth: 850 }}>
                <p className="color-white fs-32-b my-2 font-weight-bold">1. Information We Collect</p>
                <p className='color-white my-2 op-8'>We may collect the following types of information:</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Personal Information:</p>
                <p className="color-white op-8">Name, email address, phone number, and payment information when you register or invest.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Technical Information: </p>
                <p className="color-white op-8">IP address, browser type, operating system, and device information through cookies and similar technologies.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Transaction Data: </p>
                <p className="color-white op-8">Details of your cryptocurrency transactions, wallet addresses, and investment history.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Communications: </p>
                <p className="color-white op-8">Records of inquiries, feedback, or support requests.</p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">2. How We Use Your Information</p>
                <p className='color-white my-2 op-8'>We use the information collected for purposes such as:</p>
                <p className="color-white op-8 mt-5">
                    <li>Providing and managing our investment services.</li>
                    <li>Verifying your identity and ensuring compliance with legal requirements (e.g., AML/KYC regulations).</li>
                    <li>Improving our platform’s functionality and security.</li>
                    <li>Communicating with you about updates, investment opportunities, or policy changes.</li>
                    <li>Conducting research to enhance user experience and service offerings.</li>
                </p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">3. Sharing and Disclosure of Information</p>
                <p className='color-white my-2 op-8'>We do not sell your personal information. However, we may share your data under the following circumstances:</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>With Service Providers:</p>
                <p className="color-white op-8">To facilitate platform operations, such as payment processing or customer support.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>For Legal Compliance: </p>
                <p className="color-white op-8">To comply with laws, regulations, or court orders.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Transaction Data: </p>
                <p className="color-white op-8">During Business Transactions:</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>Communications: </p>
                <p className="color-white op-8">In the event of a merger, acquisition, or sale of assets.</p>
                <p className='color-white fs-h5 mt-5 mb-2 font-weight-bold'>With Your Consent: </p>
                <p className="color-white op-8">If you authorize us to share your information for specific purposes.</p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">4. Security of Your Information</p>
                <p className='color-white my-2 op-8'>We implement robust security measures to protect your data, including encryption, secure servers, and regular security audits. However, no system is completely secure. Please take precautions to protect your information by using strong passwords and enabling two-factor authentication.</p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">5. Cookies and Tracking Technologies</p>
                <p className='color-white my-2 op-8'>We use cookies to:</p>
                <p className="color-white op-8 mt-5">
                    <li>Analyze traffic and user behavior.</li>
                    <li>Remember your preferences and enhance user experience.</li>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can manage cookie preferences in your browser settings.

                </p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">6. Your Rights</p>
                <p className='color-white my-2 op-8'>Depending on your jurisdiction, you may have rights including:</p>
                <p className="color-white op-8 mt-5">
                    <li>Accessing your data.</li>
                    <li>Correcting inaccurate or incomplete information.</li>
                    <li>Requesting deletion of your personal data.</li>
                    <li>Opting out of certain data processing activities, such as marketing communications.</li>
                    To exercise these rights, contact us at hello@defina.com
                </p>

                <p className="color-white fs-32-b mt-5 my-2 font-weight-bold">7. Contact Us</p>
                <p className='color-white op-8 mt-5'>
                    If you have any questions, concerns, or feedback regarding this Privacy Policy or our data practices, please contact us: <br />
                    Email: hello@defina.com <br />
                    Phone: 481-863-4653 <br />
                    Mailing Address: 123 Main Street, Apartment 4B, Springfield, IL 62701, USA
                </p>

                <p className='color-white op-8 mt-5'>Thank you for trusting DeFina Crypto Fund. We are committed to protecting your privacy and ensuring transparency in our practices.</p>

            </div>
        </div>
    </div>
}

export default Privacy;