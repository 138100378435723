import gravitonImg from "../assets/companies/graviton.png";
import serenityImg from "../assets/companies/serenity.png";
import solarImg from "../assets/companies/solar.png";
import demoverseImg from "../assets/companies/demoverse.png";
import superlocalImg from "../assets/companies/superlocal.png";

export const reviewData = [
    {
        logo: gravitonImg,
        user:'https://content.solsea.io/files/thumbnail/1636841052544-551903284-Icon2.jpg',
        company: "Graviton",
        review: "DeFina’s focus on stability and risk management has provided consistent returns in a volatile market. Their transparency and expertise make them a trusted partner.",
        name: "Ash Crypto",
        role: "Founder",
        // profile: "https://www.linkedin.com/in/troy-murray-2461a693/"
    },
    {
        logo: serenityImg,
        user:"https://content.solsea.io/files/thumbnail/1661238175476-327485593.png",
        company: "Serenity Shield",
        review: "Working with DeFina has been fantastic. They’ve made navigating the crypto space stress-free with their solid strategies and consistent results.",
        name: "Vyacheslav",
        role: "COO",
        // profile:"https://www.linkedin.com/in/hugues-svay-5b387248/"
    },
    {
        logo: solarImg,
        user:'https://content.solsea.io/files/thumbnail/1658494686989-735188276.png',
        company: "Solar",
        review: "When we first considered crypto investments, we needed a partner who understood risk management. DeFina not only met our expectations but exceeded them, providing consistent returns while keeping volatility in check.",
        name: "Azamat",
        role: "Founder",
        // profile:"https://twitter.com/WillemsTRA"
    },
    {
        logo: demoverseImg,
        user:'https://content.solsea.io/files/thumbnail/1676126069867-720424217.jpg',
        company: "Demo Verse",
        review: "DeFina is the gold standard in crypto investment management! Their conservative approach and emphasis on transparency have made them a perfect partner in this rapidly evolving space.",
        name: "Gor",
        role: "Founder",
        // profile: "https://www.linkedin.com/in/annajchan"
    },
    {
        logo: superlocalImg,
        user:'https://content.solsea.io/files/thumbnail/1665996308256-582758459.png',
        company: "Superlocal",
        review: "DeFina’s strategies resulted in a consistent 20% APY in both BTC and USD investments for our firm. Their risk management approach significantly mitigated exposure to market volatility.",
        name: "zombie cash",
        role: "Founder",
        // profile:"https://www.linkedin.com/in/connor-smith-1b676a1b/"
    },
]
