export const faqData =
    [
        {
            question: "What is DeFina?",
            answer: "DeFina is a secure and conservative crypto fund that focuses on managing investments in the cryptocurrency space. We specialize in delivering stable returns through advanced strategies like liquidity provision (LP) management and risk mitigation. "
        },
        {
            question: "What kind of returns can I expect with DeFina?",
            answer: "Our platform targets a consistent minimum annual percentage yield (APY) of 20% across USD, BTC, and ETH, depending on market conditions and the strategies employed."
        },
        {
            question: "How does DeFina manage risk?",
            answer: "We use advanced risk mitigation techniques, including diversified portfolio management, insurance mechanisms like Nexus Mutual, and automated tools for managing market volatility. Our approach ensures a balance between potential returns and risk exposure."
        },
        {
            question: "What types of assets do you work with?",
            answer: "We primarily work with stable and well-established assets like BTC, ETH, and USD-pegged stablecoins. Additionally, we strategically invest in promising DeFi projects and protocols with high growth potential."
        },
        {
            question: "Is my investment secure?",
            answer: "Yes, DeFina employs state-of-the-art security measures, including encryption, two-factor authentication (2FA), and insurance for certain transactions. We also offer full transparency, allowing you to track your investments in real time."
        },
        {
            question: "Can I track my portfolio’s performance?",
            answer: "Absolutely! You can track your portfolio’s performance in real time using our analytics tools and integrations like Nansen.ai, providing detailed insights into your yield, transactions, and overall performance."
        },
        {
            question: "What’s the minimum investment amount?",
            answer: "Our minimum investment requirements vary depending on the strategy. For high-yield strategies, the minimum can be as low as $50,000. Contact us for detailed requirements tailored to your goals."
        },
        {
            question: "How does liquidity provision (LP) management work?",
            answer: "Liquidity provision involves depositing assets into decentralized pools to earn fees or rewards. Our automated tools optimize these deposits to maximize returns while managing market volatility and risks."
        },
        {
            question: "Do you invest in high-risk opportunities like meme-coins?",
            answer: "Yes, for clients interested in high-yield strategies, we offer options like meme-coin trading and MEV arbitrage. These strategies are optional and designed for clients with a higher risk appetite."
        },
        {
            question: "How transparent is DeFina’s investment process? ",
            answer: "Transparency is one of our core values. Clients have access to their wallets and private keys, can track all transactions, and receive detailed reports on the performance of their investments."
        },
        {
            question: "How do I get started with DeFina?",
            answer: "Getting started is easy! Contact us via [Insert Email/Contact Form] to schedule a consultation. We’ll guide you through the onboarding process, including compliance checks like KYC/AML verification."
        },
        {
            question: "Can I withdraw my funds at any time?",
            answer: "Withdrawal policies vary by strategy. Some funds may be locked for a specific duration, while others offer more flexibility. Please consult with our team to understand the terms of your chosen investment."
        },
        {
            question: "Do you comply with regulatory standards?",
            answer: "Yes, we strictly adhere to all applicable legal and regulatory requirements, including AML (Anti-Money Laundering) and KYC (Know Your Customer) standards."
        },
        {
            question: "What sets DeFina apart from other crypto funds?",
            answer: "Our conservative and research-driven approach focuses on stable returns, advanced risk management, and full transparency. With over 5 years of experience and a proven track record, we prioritize client trust and long-term success."
        },
        {
            question: "How can I contact your team for more information?",
            answer: "You can reach us via: \nEmail: Defina@Defina.com \nPhone:+(374) 55220490 \nWebsite: Defina.com \nWe’re happy to answer any further questions or schedule a consultation to discuss your investment goals."
        },
    ]
