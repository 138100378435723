import React, { useState, useEffect } from 'react';
import coinlogo from '../../assets/images/coin.png';
import research from "../../assets/images/research.png";
import expand from "../../assets/images/expand.png";
import security from "../../assets/images/security.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { reviewData } from 'src/config/reviewData';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import ReviewCard from 'src/components/reviewCard';
import CommonBtn from 'src/components/button/CommonBtn';
import "../../styles/style.css";
import SelectBox from 'src/components/selectBox';
import chart from "../../assets/images/chart.png"
import curveImg from "../../assets/images/curve.svg";

import FormModal from 'src/components/formModal';
function getCarousel() {
    const { innerWidth: width, innerHeight: height } = window;
    if (width <= 600 && height) {
        return true;
    } else {
        return false;
    }
}
const Invest = () => {
    const [carousel, setCarousel] = useState(getCarousel());
    const options = [
        { value: 'year', label: 'Last year' },
        { value: 'month', label: 'Last month' },
        { value: 'week', label: 'Last week' },
    ]
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setOpen(false);
    };
    useEffect(() => {
        function handleResize() {
            console.log(getCarousel());
            setCarousel(getCarousel());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return <div className='invest-section' id='invest'>
        <div className="effect-l left-effect">
            <div className='dot left-dot'></div>
        </div>
        <div className="effect-r right-effect">
            <div className='dot right-dot'></div>
        </div>
        <div className='container'>
            <div className="d-flex flex-column align-items-center">
                <p className='color-white fs-h2'>How We
                    <img src={coinlogo} alt="coin logo" className='mlr-10' />
                    invest
                </p>
                <p style={{ maxWidth: '555px', opacity: 0.8 }} className='color-white mtb-25 fs-b text-center'>DeFina employs an investment strategy grounded in thorough research, rigorous risk management, and strategic diversification. We carefully select the most promising DeFi projects and assets to ensure your investments are optimized for long-term success.</p>
            </div>
            <div className="invest-effect">
                <div class="invest-container">
                    <img src={curveImg} alt="" srcset="" />
                    <div class="circle1"></div>
                    <div class="circle2"></div>
                    <div class="circle3"></div>
                </div>
            </div>
            <div className="d-flex flex-lg-row flex-md-row flex-sm-column justify-content-between align-items-center">
                <div className="d-flex flex-column invest-item-container text-center first-invest">
                    <div className="invest-item">
                        <div className="invest-item-logo">
                            <img src={research} alt="research" />
                        </div>
                    </div>
                    <p className='color-white fs-s0 mtb-25'>Research & Analysis</p>
                    <p className='color-white mtb-10 fs-b' style={{ opacity: 0.8 }}>Conducting comprehensive market analysis to uncover top DeFi opportunities.</p>

                </div>
                <div className="d-flex flex-column invest-item-container text-center second-invest">
                    <div className="invest-item">
                        <div className="invest-item-logo">
                            <img src={expand} alt="expand" />
                        </div>
                    </div>
                    <p className='color-white fs-s0 mtb-25'>Research & Analysis</p>
                    <p className='color-white mtb-10 fs-b' style={{ opacity: 0.8 }}>Maintaining a balanced portfolio across diverse DeFi assets to mitigate risk.</p>
                </div>
                <div className="d-flex flex-column invest-item-container text-center" style={{ marginTop: '150px' }}>
                    <div className="invest-item">
                        <div className="invest-item-logo">
                            <img src={security} alt="security" />
                        </div>
                    </div>
                    <p className='color-white fs-s0 mtb-25'>Research & Analysis</p>
                    <p className='color-white mtb-10 fs-b' style={{ opacity: 0.8 }}>Implementing state-of-the-art security measures to safeguard your investments.</p>
                </div>

            </div>
            <div className="client-container" style={{ marginTop: '200px' }}>
                <p className='fs-h2 color-white' >Hear from</p>
                <p className='fs-h2 color-primary '>Our Clients</p>
                <div className='review-container mt-5'>
                    <Swiper slidesPerView={carousel ? 1 : 3} spaceBetween={30} centeredSlides={true}
                        pagination={{ enabled: true }} navigation={true} modules={[Navigation, Pagination]}
                        loop={true}>
                        {
                            reviewData.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <ReviewCard data={item} />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                <div className='d-flex'>
                    <CommonBtn title="Become a client" className="active-btn color-white line-block" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
                </div>

            </div>
            <div className="overtime-container" style={{ marginTop: '100px' }}>
                <div className="d-flex justify-content-center align-items-center mtb-25">
                    <p className='color-white fs-h2'>Overtime</p>&nbsp;&nbsp;&nbsp;
                    <p className='color-primary fs-h2'> Performance</p>
                </div>
                <p className='color-white mtb-10 text-center'>Demonstrating Consistent Year-Over-Year Growth</p>
            </div>
            <div className="chart-container">
                <div className="d-flex justify-content-between">
                    <p className='color-white'>Achieving steady returns throughout 2024</p>
                    <SelectBox options={options} />
                </div>
                <img src={chart} className="mtb-10" alt="chart" srcset="" style={{ width: '100%' }} />
            </div>
        </div>

        <FormModal isOpen={open} onClose={closeModal} />
    </div>
}

export default Invest;