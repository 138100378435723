import armanImg from "../assets/images/team/arman.png";
import samImg from "../assets/images/team/sam.png";
import perryImg from "../assets/images/team/perry.png"

export const teamData = [
  {
    name: 'Arman',
    role: 'CEO and Founder',
    imgUrl: armanImg,
    upwork: 'https://www.upwork.com/freelancers/astepanyan',
    linkedin: 'https://www.linkedin.com/in/arman-stepanyan-67412a146/',
    contra: "https://contra.com/arman_stepanyan",
    about: "Bringing expertise in DeFi strategy and product management, backed by a proven track record of building successful crypto projects.",
  },
  {
    name: 'Sam',
    role: 'CTO and Co-Founder',
    imgUrl: samImg,
    upwork: 'https://www.upwork.com/freelancers/~010253f1848f69430b',
    linkedin: 'https://www.linkedin.com/in/samvel-barseghyan-305085195',
    contra: "https://contra.com/samvel_barseghyan",
    about: "Specializing in market analysis and risk mitigation to ensure strategies align with current market conditions.",
  },
  {
    name: 'Perry',
    role: 'Asset Management Expert',
    imgUrl: perryImg,
    upwork: 'https://www.upwork.com/freelancers/~010253f1848f69430b',
    linkedin: 'https://www.linkedin.com/in/samvel-barseghyan-305085195',
    contra: "https://contra.com/samvel_barseghyan",
    about: "Experienced in diversified asset management with a focus on optimizing DeFi yields and maximizing returns.",
  }
];