import React from 'react';
import TeamMemberCard from 'src/components/teamMemberCard';
import { teamData } from 'src/config/teamData';

const Team = () => {

    return (
        <section className="team-section color-white text-center" id='team'>
            <div className="d-flex justify-content-center align-items-center mtb-50">
                <p className='color-white fs-h2'>Our</p> &nbsp;&nbsp;
                <p className='color-primary fs-h2'>Team</p>
            </div>
            <p className="color-white fs-b" style={{opacity:0.8}}>Our team combines years of experience in crypto asset management,</p>
            <p className="color-white fs-b" style={{opacity:0.8}}>trading, and DeFi, ensuring a well-roundsd approach</p>
            <p className="color-white fs-b" style={{opacity:0.8}}>todelivering consistent returns for our clients</p>
            <div className="d-flex flex-lg-row flex-md-row flex-sm-column justify-content-center align-item-center mtb-50" style={{gap:'45px'}}>
                {teamData.map((member, key) => (
                    <div className="team-member-card" key={key} style={{padding:20}}>
                        <TeamMemberCard data={member} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Team;