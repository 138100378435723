import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    background: 'linear-gradient(264.82deg, rgba(255, 255, 255, 0.045) 2.47%, rgba(255, 255, 255, 0.03) 98.18%),linear-gradient(0deg, rgba(1, 1, 1, 0.2), rgba(1, 1, 1, 0.2))',
    border: '1px solid rgba(156, 255, 160, 0.2)',
    // borderImageSource: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(99.42% 581.75% at -2.92% 73.44%, rgba(199, 254, 158, 0.4) 0%, rgba(199, 254, 158, 0) 100%)',
    color: '#ffffff',
    padding: '1.5px 12px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'rgba(156, 255, 160, 0.2)',
    },
    borderRadius: '999px'
  }),
  menu: (provided) => ({
    ...provided,
    background: '#111',
    borderRadius: 12,
    border: '1px solid rgba(156, 255, 160, 0.2)',
    overflow: 'hidden',
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#282828' : state.isFocused ? '#282828' : '#111',
    color: 'white',
    '&:hover': {
      background: '#555',
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A7ABBE',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#999',
    '&:hover': {
      color: '#fff',
    }
  }),
  indicatorSeparator: () => ({
    // Removes the separator
    display: 'none'
  }),
  // You may also need to adjust other parts like indicatorsContainer, clearIndicator, etc.
};

const SelectBox = ({ options, className, placeholder }) => {
  return <Select
    className={className}
    styles={customStyles}
    options={options}
    placeholder={placeholder}
    theme={(theme) => ({
      ...theme,
      borderRadius: 16,
      colors: {
        ...theme.colors,
        primary25: '#666', // Color for options hover
        primary: '#000', // Color for the control when it is open
      },
    })}
  />
}

export default SelectBox;