import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.svg';
import instagram from "../../assets/logos/instagram.png";
import twitter from "../../assets/logos/twitter.png";
import facebook from "../../assets/logos/facebook.png";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
function getCarousel() {
  const { innerWidth: width, innerHeight: height } = window;
  if (width <= 600 && height) {
    return true;
  } else {
    return false;
  }
}
const Footer = () => {
  const navigate = useNavigate()
  const [carousel, setCarousel] = useState(getCarousel());
  useEffect(() => {
    function handleResize() {
      console.log(getCarousel());
      setCarousel(getCarousel());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <footer className="footer">
      <div className='container'>
        {!carousel &&
          <div className="d-flex justify-content-between">
            <img
              style={{ width: '103px', cursor:'pointer'}}
              src={logo}
              className="d-inline-block align-top header-logo"
              alt="Armstep Logo"
              onClick={()=> {navigate('/')}}
            />
            <div className="d-flex justify-content-center" style={{ gap: '28px' }}>
              <Link to="home" smooth={true} duration={500} className='color-white fs-c'> About </Link>
              <Link to="invest" smooth={true} duration={500} className='color-white fs-c'> How we invest </Link>
              <Link to="team" smooth={true} duration={500} className='color-white fs-c'> Portfolio </Link>
              <Link to="faq" smooth={true} duration={500} className='color-white fs-c'> FAQ </Link>
            </div>
            <div className="d-flex justify-content-center" style={{ gap: '16px' }}>
              <img src={instagram} alt="instagram" className="log" width={24} height={24} style={{ opacity: 0.4 }} />
              <img src={twitter} alt="twitter" className="log" width={24} height={24} style={{ opacity: 0.4 }} />
              <img src={facebook} alt="facebook" className="log" width={24} height={24} style={{ opacity: 0.4 }} />
            </div>
          </div>
        }
        {carousel &&
          <div className="d-flex flex-sm-column justify-content-between">
            <div className="d-flex justify-content-between">
              <img
                style={{ width: '103px' }}
                src={logo}
                className="d-inline-block align-top header-logo"
                alt="Armstep Logo"
              />
              <div className="d-flex justify-content-center" style={{ gap: '28px' }}>
                <img src={instagram} alt="instagram" className="log" width={24} height={24} style={{ opacity: 0.4 }} />
                <img src={twitter} alt="twitter" className="log" width={24} height={24} style={{ opacity: 0.4 }} />
                <img src={facebook} alt="facebook" className="log" width={24} height={24} style={{ opacity: 0.4 }} />

              </div>
            </div>
            <div className="d-flex justify-content-start mt-3" style={{ gap: '16px' }}>
              <Link to="home" smooth={true} duration={500} className='color-white fs-c'> About </Link>
              <Link to="invest" smooth={true} duration={500} className='color-white fs-c'> How we invest </Link>
              <Link to="team" smooth={true} duration={500} className='color-white fs-c'> Portfolio </Link>
              <Link to="faq" smooth={true} duration={500} className='color-white fs-c'> FAQ </Link>
            </div>
          </div>
        }
        <div className="d-flex flex-lg-row flex-md-row flex-sm-column justify-content-between mtb-25" style={{ gap: '20px' }}>
          <div className='color-white fs-c' style={{ opacity: 0.5 }}>© DeFina, 2024. All rights reserved</div>
          <div className="d-flex" style={{ gap: '16px', opacity: 0.2 }}>
            <Link onClick={()=> {navigate('/privacy')}}  className='color-white fs-c'>Privacy Policy</Link>
            <Link className='color-white fs-c'>Terms and Condition</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;