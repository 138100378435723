import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import { Outlet } from 'react-router-dom';
const MainLayout = (props) => {
    const starCount = 200; // Number of stars
    const [stars, setStars] = useState([]);

    // Function to generate random positions and sizes for stars
    const generateStars = () => {
        const height =
            document.documentElement.scrollHeight || document.body.scrollHeight;
        const newStars = [];
        for (let i = 0; i < starCount; i++) {
            const x = Math.random() * window.innerWidth; // Random x position
            const y = Math.random() * height; // Random y position
            const size = Math.random() * 2 + 1; // Random size for the star (between 1px and 3px)
            const opacitySpeed = Math.random() * 0.05 + 0.01; // Random speed for opacity change

            newStars.push({ x, y, size, opacitySpeed });
        }
        setStars(newStars);
    };

    // Generate stars when the component mounts
    useEffect(() => {
        generateStars();
    }, []);

    // Function to animate the twinkling of the stars
    useEffect(() => {
        const animateStars = () => {
            setStars((prevStars) =>
                prevStars.map((star) => {
                    // Animate opacity using sine wave
                    const newOpacity = (Math.sin(star.opacitySpeed * Date.now() / 1000));
                    const newOpacitySpeed = star.opacitySpeed + Math.random(); // Update speed slightly

                    return { ...star, opacity: newOpacity, opacitySpeed: newOpacitySpeed };
                })
            );

            requestAnimationFrame(animateStars); // Keep the animation going
        };

        animateStars(); // Start animation
    }, []);
    return (
        <div className="body">
            <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', overflow: 'hidden', zIndex: -1 }}>
                {/* Render Stars */}
                {stars.map((star, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            width: `${star.size}px`,
                            height: `${star.size}px`,
                            backgroundColor: '#9CFFA0',
                            borderRadius: '50%',
                            transform: `translate(${star.x}px, ${star.y}px)`,
                            opacity: star.opacity,
                            pointerEvents: 'none', // Disable pointer events for the stars
                        }}
                    />
                ))}
            </div>
            <Header />
            <div className="content">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default MainLayout