import binance from "../assets/logos/binance.svg";
import coinbase from "../assets/logos/coinbase.svg";
import coinfirm from "../assets/logos/coinfirm.svg";
import  fireblocks  from "../assets/logos/fireblocks.svg";
import  kraken  from "../assets/logos/kraken.svg";
import  metamask  from "../assets/logos/metamask.svg";
import  trezor  from "../assets/logos/trezor.svg";

export const partnersData = [
    {
        logo: binance,
        title: "BINANCE"
    },
    {
        logo: coinbase,
        title:"COINBASE"
    },
    {
        logo: coinfirm,
        title: "COINFIRM"
    },
    {
        logo: fireblocks,
        title: "FIREBLOCKS"
    },
    {
        logo: kraken,
        title: "KRAKEN"
    },
    {
        logo:metamask,
        title:"METAMASK"
    },
    {
        logo:trezor,
        title:"TREZOR"
    }
]