import React from 'react';
import Home from './home';
import Invest from './invest';
import Team from './team';
import FAQ from './faq';
const HomePage = () => {
    return <>
        <Home />
        <Invest />
        <Team />
        <FAQ />
    </>
    
}

export default HomePage;