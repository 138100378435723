import React, { useEffect, useState } from 'react';
import vector from '../../assets/logos/vector.svg';
import key from '../../assets/images/key-logo.svg';
import Chip from "../../assets/images/chip.png";
import CommonBtn from 'src/components/button/CommonBtn';
import { partnersData } from 'src/config/partnersData';
import FormModal from 'src/components/formModal';
function getCarousel() {
    const { innerWidth: width, innerHeight: height } = window;
    if (width <= 600 && height) {
        return true;
    } else {
        return false;
    }
}
const Home = () => {
    const [open, setOpen] = useState(false);

    const closeModal = () => {
        document.body.classList.remove('modal-open');
        setOpen(false);
    };
    const [carousel, setCarousel] = useState(getCarousel());
    useEffect(() => {
        function handleResize() {
            setCarousel(getCarousel());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return <div className='home-section' id='home'>
        <div className='d-flex' style={{ paddingTop: '145px' }}>
            <div className="circle" style={{ borderRight: '1px solid' }}>
                <div className="effect-l left-effect">
                    <div className='dot left-dot'></div>
                </div>
            </div>
            <div className="circle-center d-flex flex-column justify-content-center align-items-center">
                <div className='d-flex justify-content-center crypto'>
                    <img src={vector} alt="vector" srcset="" width={20} height={20} />
                    <p className='fs-c color-white' style={{ margin: 'auto' }}>Crypto Found</p>
                </div>
                <h1 className='color-white home-content'>
                    <span>Your Keys</span>
                    <img src={key} alt="key logo" srcset="" style={{ margin: '10px' }} />
                    <br></br>
                    <span>to Crypto Success</span>
                </h1>
                <span className='fs-b color-white mtb-10 home-detail' style={{ opacity: 0.7 }}>
                    Achieve Financial Growth with Our Expert-Driven Strategies
                </span>
                {
                    !carousel && <div className='d-flex' style={{ gap: '8px' }}>
                        <CommonBtn title="Portfolio" className="common-btn color-white portfolio" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
                        <CommonBtn title="Get Started" className="active-btn color-white" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
                    </div>
                }

            </div>

            <div className="circle" style={{ borderLeft: '1px solid' }}>
                <div className="effect-r right-effect">
                    <div className='dot right-dot'></div>
                </div>
            </div>
        </div>
        {
            carousel && <div className='d-flex justify-content-center mtb-25' style={{ gap: '8px' }}>
                <CommonBtn title="Portfolio" className="common-btn color-white portfolio" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
                <CommonBtn title="Get Started" className="active-btn color-white" onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
            </div>
        }
        <div className="d-flex flex-column justify-content-center align-items-center mtb-50" style={{ gap: '32px' }}>
            <p className='color-primary fs-o'>OUR PARTNERS</p>
            <div className="partner-container" style={{ gap: "16px", display: 'inline-flex' }}>
                {partnersData.map((item, index) => (
                    <div className="partner">
                        <img src={item.logo} alt="title" key={index} srcset="" />
                    </div>
                ))}
            </div>
        </div>
        <div className="container">
            <div className="d-flex flex-sm-column flex-md-row flex-lg-row mtb-50" style={{ gap: '10px' }}>
                <div className="d-flex flex-column justify-content-center align-items-start">
                    <p className='fs-h2 color-white'>Empowering your</p>
                    <p className='fs-h2 color-white'>Future through</p>
                    <p className='fs-h2 color-primary'>DeFi Investments</p>
                    <p className='fs-c color-white mtb-25 pe-4' style={{ opacity: '0.8' }}>DeFina is an advanced investment management platform that provides clients with a secure and conservative approach to crypto investing. Our focus is on leveraging the most stable assets and reliable financial instruments to ensure a consistent annual percentage yield (APY) of at least 20% in USD, BTC, and ETH.</p>
                    <CommonBtn className="active-btn mtb-50 color-white" title="Become a client" style={{ marginLeft: '0px' }} onClick={() => { document.body.classList.add('modal-open'); setOpen(true) }} />
                </div>
                <div className='star-container'>
                    <div className="d-flex flex-lg-row flex-md-row flex-sm-column" style={{ marginRight: '50px' }}>
                        <div className='no-wrap'>
                            <img src={vector} alt="vector" srcset="" width={20} />
                            <span className='color-white mlr-10' style={{ fontSize: '14px' }}>Expertise in DeFi</span>
                        </div>
                        <div className='no-wrap'>
                            <img src={vector} alt="vector" srcset="" width={20} />
                            <span className='color-white mlr-10' style={{ fontSize: '14px' }}>Secure and Transparent</span>
                        </div>
                    </div>
                    <div className='no-wrap'>
                        <img src={vector} alt="vector" srcset="" width={20} />
                        <span className='color-white mlr-10' style={{ fontSize: '14px' }}>High Growth Potential</span>
                    </div>
                    <div class="shooting_star"></div>
                    <div className="night">
                        <div class="shooting_star"></div>
                    </div>
                    <div className="circle-animation">
                        <div class="dot-animation"></div>
                        <div className='circle-seq first'>
                            <div className='circle-seq second'>
                                <div className='circle-seq thrid'>
                                    <img src={Chip} alt="chip" srcset="" width={114} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FormModal isOpen={open} onClose={closeModal} />
    </div>

}

export default Home;